<template>
  <div class="list-content" v-loading="loading">
    <p class="save-title" style="margin-top: 4px">评论审核设置</p>

    <el-form class="save-content" label-width="82px">
      <el-form-item label="审核设置：">
        <div>
          <el-radio v-model="config.comment_check_type" :label="1">先发后审</el-radio>
          <el-radio v-model="config.comment_check_type" :label="0">先审后发</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="评论间隔：">
        <div class="flex flex-align-center">
          <el-input-number v-model="config.interval" :min="0"></el-input-number>
          <span style="margin-left: 10px">秒</span>
        </div>
      </el-form-item>
    </el-form>
    <div>
      <p class="save-title">评论过滤设置</p>
    </div>

    <el-form class="save-content" label-width="82px">
      <el-form-item label="过滤设置：">
        <div>
          <el-radio v-model="config.is_open_filter_word" :label="1">开启</el-radio>
          <el-radio v-model="config.is_open_filter_word" :label="0">关闭</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="过滤词库：" v-if="config.is_open_filter_word">
        <div>
          <el-radio v-model="filter_all_comment" :label="1">全部</el-radio>
          <el-radio v-model="filter_all_comment" :label="0">部分</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="选择词库：" v-if="!filter_all_comment && config.is_open_filter_word">
        <div>
          <el-select v-model="config.filter_words" placeholder="选择词库" multiple>
            <el-option v-for="(word, index) in words" :key="word.id" :label="word.title" :value="word.id">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <div>
      <p class="save-title">内容过滤设置</p>
    </div>

    <el-form class="save-content" label-width="82px">
      <el-form-item label="过滤设置：">
        <div>
          <el-radio v-model="config.is_open_content_filter_word" :label="1">开启</el-radio>
          <el-radio v-model="config.is_open_content_filter_word" :label="0">关闭</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="过滤词库：" v-if="config.is_open_content_filter_word">
        <div>
          <el-radio v-model="filter_all_content" :label="1">全部</el-radio>
          <el-radio v-model="filter_all_content" :label="0">部分</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="选择词库：" v-if="!filter_all_content && config.is_open_content_filter_word">
        <div>
          <el-select v-model="config.filter_content_words" placeholder="选择词库" multiple>
            <el-option v-for="(word, index) in words" :key="word.id" :label="word.title" :value="word.id">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <div>
      <p class="save-title">内容审核设置</p>
    </div>

    <el-form class="save-content" label-width="82px">
      <el-form-item label="审核设置：" style="margin-bottom: 0">
        <div>
          <el-radio v-model="config.content_check_type" :label="1">先发后审</el-radio>
          <el-radio v-model="config.content_check_type" :label="0">先审后发</el-radio>
        </div>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { setCycCircle, getCycCircle } from "../api/circle-check";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: { FixedActionBar },
  data() {
    return {
      loading: false,
      config: [],
      filter_all_comment: true,
      filter_all_content: true,
    };
  },
  watch: {
    filter_all_comment(val) {
      if (val) {
        this.config.filter_words = [];
      }
    },
    filter_all_content(val) {
      if (val) {
        this.config.filter_content_words = [];
      }
    },
  },
  created() {
    this.getDatail();
  },
  methods: {
    getDatail() {
      this.loading = true;
      getCycCircle()
        .then((res) => {
          this.config = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveConfig() {
      this.loading = true;
      setCycCircle({ config: this.config })
        .then((res) => {
          this.$message.success(res.msg);
          this.getDatail();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.save-title {
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-left: 3px #3479ef solid;
  line-height: 16px;
  margin-bottom: 19px;
}

.save-content {
  padding: 12px 4px 10px;

  .el-form-item__label,
  ::v-deep.el-form-item__label {
    white-space: nowrap;
  }
}
</style>
